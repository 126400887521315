import React, { useMemo, useRef } from "react";
import "./carousel-desk.scss";
import ShowContainer from "../ShowContainer.jsx";
import { useSelector } from "react-redux";
import ReactOwlCarousel from "react-owl-carousel";
import useFixCaruselDesk from "../../../hooks/useFixCaruselDesk.js";
import CarouselDeskHeader from "./carousel_desk_header/CarouselDeskHeader.jsx";

export default function CarouselDesk({ items = [], title, children, showAllLink = "" }) {
  const isMobileSize = useSelector((state) => state.deviceSize.isMobileSize);
  const isMobileHeader = useSelector((state) => state.deviceSize.isMobileHeader);
  const isLsm = useSelector((state) => state.deviceSize.isLsm);

  const sliderRef = useRef(null);

  const slidesToShow = useMemo(() => {
    if (isMobileSize) return 2;
    if (isLsm) return 3;
    if (isMobileHeader) return 4;
    return 6;
  }, [isLsm, isMobileSize, isMobileHeader]);

  const manyLength = items.length > slidesToShow;

  useFixCaruselDesk({ sliderRef, children });

  return (
    <ShowContainer condition={items.length}>
      <div className="carousel-desk">
        <CarouselDeskHeader
          title={title}
          showAllLink={showAllLink}
          slidesToShow={slidesToShow}
          sliderRef={sliderRef}
          manyLength={manyLength}
        />
        {manyLength ? (
          <ReactOwlCarousel
            ref={sliderRef}
            startPosition={0}
            className="carousel-desk__items"
            margin={10}
            loop
            responsiveRefreshRate={0}
            mouseDrag={true}
            dots={false}
            items={slidesToShow}>
            {React.Children.map(children, (child, index) => (
              <div key={index} id={`item-${index}`} className="carousel-item" data-index={index}>
                {child}
              </div>
            ))}
          </ReactOwlCarousel>
        ) : (
          <div className={`carousel-desk__items carousel-plug carousel-plug-${slidesToShow}`}>
            {children}
          </div>
        )}
      </div>
    </ShowContainer>
  );
}
