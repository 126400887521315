import React from "react";
import Logo from "../header/logo/Logo";
import FindGame from "../find_game/FindGame";
import "./aside-menu.scss";
import BonusMenu from "./bonus_menu/BonusMenu";
import MenuProviders from "./menu_providers/MenuProviders";
import IconClip from "../../mui/JSXIcons/IconClip";
import { useTranslation } from "react-i18next";
import IconTg from "../../mui/JSXIcons/IconTg";
import IconSupport from "../../mui/JSXIcons/IconSupport";
import SocialIcons from "./social_icons/SocialIcons";

export default function AsideMenu() {
  const { t } = useTranslation();
  const link = "";
  const isDark = true;
  const darkSrc = isDark ? "dark=1" : "";

  return (
    <aside className="aside-menu ">
      <Logo />
      <div className="menu-content">
        <BonusMenu />
        <FindGame />
        <MenuProviders />
        <div className="menu__other">
          <h2>
            <IconClip /> {t("Other")}
          </h2>
          <div>
            <button className="menu__other__item">
              <IconTg />
              <span className="menu__other__item__title">{t("Telegram Casino")}</span>
            </button>
            <button className="menu__other__item">
              <IconSupport />
              <span className="menu__other__item__title">{t("Support")}</span>
            </button>
          </div>
        </div>
        <SocialIcons />
      </div>

      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="tg-group"
        style={{ background: isDark ? "#121212" : undefined }}>
        {t("Telegram group")}
      </a>

      <div className="widget_container" id="widget_container">
        <iframe
          id="telegram-discussion-VulpinCasinoChannel-170-29"
          src="https://t.me/VulpinCasinoChannel/170?embed=1&amp;discussion=1&amp;comments_limit=50&amp;dark=1"
          style={{
            overflow: "hidden",
            colorScheme: "light dark",
            border: "none",
            height: "100%",
            width: "100%"
          }}
        />
      </div>
    </aside>
  );
}
