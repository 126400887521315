import { createSlice } from "@reduxjs/toolkit";
import { TEST_MODE } from "./enums";
import img1 from "../components/landing/catalog_game/5.png";
import imgLogo from "../components/landing/catalog_game/3oaks.svg";
import imgProviders from "../components/landing/providers/relax.png";

const ENUM_TYPE_GAME = {
  bonus: "bonus",
  games: "games",
  providers: "providers"
};

const getUrlBonus = (id) => (TEST_MODE ? img1 : `/images/bonus_games/${id}.png`);
const runningGamesId = JSON.parse(localStorage.getItem("runningGamesId"));

const getUrlProviders = (name) => {
  const id = name.toLowerCase();
  return TEST_MODE ? imgProviders : `/images/providers/${id}.png`;
};

const getUrlLogo = (name) => {
  const id = name.toLowerCase();
  return TEST_MODE ? imgLogo : `/images/providers/logo/${id}.svg`;
};

const transformGames = (payload, type) => {
  return payload
    .filter((el) => el.name)
    .map((el) => ({
      ...el,
      id: el.id || el.game_id,
      logo: getUrlLogo(el.name),
      image:
        el.image ||
        (type === ENUM_TYPE_GAME.providers ? getUrlProviders(el.name) : getUrlBonus(el.id))
    }));
};

// - основная информация стора о играх
const gamesSlice = createSlice({
  name: "games",
  initialState: {
    providers: [],
    favoriteGames: [],
    favoriteGamesId: [],
    hitGames: [],
    bonusGames: [],
    providersGames: [],
    gameCategories: [],
    excludeDemoMode: [],
    runningGamesId: runningGamesId || [],
    runningGames: [],
    gamesById: {}
  },
  reducers: {
    setProviders(state, newAction) {
      state.providers = transformGames(newAction.payload, ENUM_TYPE_GAME.providers);
    },
    setBonusGames(state, newAction) {
      state.bonusGames = transformGames(newAction.payload, ENUM_TYPE_GAME.bonus);
    },
    setHitGames(state, newAction) {
      state.hitGames = transformGames(newAction.payload);
    },
    setFavoriteGames(state, newAction) {
      const newGame = newAction.payload;
      const newGamesId = newGame.map((el) => el.game_id || el.id);
      state.favoriteGames = newGame;
      state.favoriteGamesId = newGamesId;
      localStorage.setItem("favoritesId", JSON.stringify(newGamesId));
    },
    addFavoriteGames(state, newAction) {
      const newGame = newAction.payload;
      const newGameId = newGame.game_id || newGame.id;
      if (!state.favoriteGamesId.some((el) => el == newGameId)) {
        state.favoriteGames.push(newGame);
        state.favoriteGamesId.push(newGameId);
      }
      localStorage.setItem("favoritesId", JSON.stringify(state.favoriteGamesId));
    },
    removeFavoriteGames(state, newAction) {
      const newGame = newAction.payload;
      const newGameId = newGame.game_id || newGame.id;
      state.favoriteGames = state.favoriteGames.filter(
        (el) => el.id != newGameId && el.game_id != newGameId
      );
      state.favoriteGamesId = state.favoriteGamesId.filter((el) => el != newGameId);
      localStorage.setItem("favoritesId", JSON.stringify(state.favoriteGamesId));
    },
    setProvidersGames(state, newAction) {
      state.providersGames = newAction.payload;

      const runningGamesId = state.runningGamesId;
      if (runningGamesId?.length && !state.runningGames.length) {
        for (let i = 0; i < runningGamesId.length; i++) {
          const runningId = runningGamesId[i];
          // console.log("runningId", runningId);
          const runningGame = state.gamesById?.[runningId];
          // console.log("runningGame", JSON.stringify(runningGame));
          if (runningGame) state.runningGames.push(runningGame);
        }
      }
    },
    setExcludeDemoMode(state, newAction) {
      state.excludeDemoMode = newAction.payload;
    },
    setGameId(state, newAction) {
      state.gamesById = newAction.payload;
    },
    addRunningGamesId(state, newAction) {
      const runningId = newAction.payload;
      const gamesById = state.gamesById;
      let runningGames = state.runningGames;
      let runningGamesId = state.runningGamesId;
      const runningGame = gamesById[runningId];

      if (runningGamesId.includes(runningId)) {
        runningGamesId = runningGamesId.filter((el) => el !== runningId);
        runningGames = runningGames.filter((el) => el.game_id !== runningId);
      }

      runningGamesId.unshift(runningId);
      runningGames.unshift(runningGame);

      // console.log("runningGame", runningGame);
      // console.log("runningGames", runningGames);
      // console.log("runningId", runningId);

      state.runningGames = runningGames;
      state.runningGamesId = runningGamesId;
      // console.log(runningGames);

      localStorage.setItem("runningGamesId", JSON.stringify(runningGamesId));
    }
  }
});

export default gamesSlice.reducer;

export const {
  setProviders,
  setBonusGames,
  setHitGames,
  setGameCategories,
  setFavoriteGames,
  setProvidersGames,
  setExcludeDemoMode,
  addRunningGamesId,
  setGameId,
  addFavoriteGames,
  removeFavoriteGames
} = gamesSlice.actions;
