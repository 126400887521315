import React from "react";

export default function IconPeople2() {
  return (
    <svg
      width="18.000000"
      height="18.000000"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip4156_6009">
          <rect
            id="Bold / Users / Users Group Rounded"
            rx="-0"
            width="17.000000"
            height="17.000000"
            transform="translate(0.500000 0.500000)"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#clip4156_6009)">
        <circle
          id="Vector"
          cx="6.750000"
          cy="4.500000"
          r="3.000000"
          fill="#6287A2"
          fillOpacity="1.000000"
        />
        <ellipse
          id="Vector"
          cx="6.750000"
          cy="12.750732"
          rx="5.250000"
          ry="3.000000"
          fill="#6287A2"
          fillOpacity="1.000000"
        />
        <path
          id="Vector"
          d="M11.25 6.75C12.49 6.75 13.5 5.74 13.5 4.5C13.5 3.25 12.49 2.25 11.25 2.25C10.97 2.25 10.71 2.29 10.47 2.38C10.83 3 11.03 3.73 11.03 4.49C11.03 5.26 10.83 5.99 10.47 6.61C10.71 6.7 10.97 6.75 11.25 6.75ZM12.35 15C14.22 15 15.75 13.99 15.75 12.75C15.75 11.5 14.22 10.5 12.35 10.5C12.9 11.1 13.28 11.85 13.28 12.75C13.28 13.64 12.9 14.4 12.35 15Z"
          fill="#6287A2"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
