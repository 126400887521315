import React from "react";
import { useSelector } from "react-redux";
import imgRegisterGirl from "./register-girl.png";
import imgLoginGirl from "./login-girl.png";
import { ENUM_LOGIN } from "../../../store/enums";
import { useTranslation } from "react-i18next";

export default function LoginImg() {
  const casinoName = useSelector((state) => state.common.casinoName);
  const typeLogin = useSelector((state) => state.site.modals?.data?.login?.type);

  const { t } = useTranslation();

  const imgSourceByTupe = {
    [ENUM_LOGIN.login]: imgLoginGirl,
    [ENUM_LOGIN.register]: imgRegisterGirl
  };

  return (
    <div className="login-img">
      <div className="login-img__text">
        <span className="login-img__welcome">{t("Welcome to")}</span>
        <span className="login-img__name">{casinoName}</span>
      </div>
      <img src={imgSourceByTupe[typeLogin]} />
    </div>
  );
}
