import React from "react";
import "./online.scss";

export default function Online() {
  const userOnline = 678;

  return (
    <div className="online__indicator">
      <div className="online__indicator__circle" />
      <span className="online__indicator__count">{userOnline}</span>
    </div>
  );
}
