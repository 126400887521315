import React from "react";
import "./carusel-desk-header.scss";
import useNavigateWithSearch from "../../../../hooks/useNavigateWitchSearch";
import IconArrow from "../../JSXIcons/IconArrow";
import IconFier from "../../JSXIcons/IconFier";
import IconProviders from "../../JSXIcons/IconProviders";
import CusButton from "../../custom_button/CusButton";
import IconPlay from "../../JSXIcons/IconPlay";
import IconBonus from "../../JSXIcons/IconBonus";
import DeskTitle from "../../desk_title/DeskTitle";

export default function CarouselDeskHeader({
  title,
  showAllLink,
  slidesToShow,
  sliderRef,
  manyLength
}) {
  const navigate = useNavigateWithSearch();

  const onShowAll = () => {
    if (showAllLink) navigate(`${showAllLink}`);
  };

  const onPrevClick = () => {
    for (let i = 0; i < slidesToShow; i++) {
      sliderRef.current.prev();
    }
  };

  const onNextClick = () => {
    for (let i = 0; i < slidesToShow; i++) {
      sliderRef.current.next();
    }
  };

  const imgByTitle = {
    Hit: <IconFier />,
    Providers: <IconProviders />,
    "Bonus Games": <IconBonus />,
    "History Games": <IconPlay />
  };

  const iconTitle = imgByTitle[title];

  return (
    <div className="carousel-desk__header">
      <DeskTitle iconTitle={iconTitle} title={title} />
      <div className="carousel-desk__header__right">
        <CusButton color="blue" text="Show all" onClick={onShowAll} />
        <div className="carousel-desk__controls">
          <button
            disabled={!manyLength}
            className="carousel-desk__controls__prev"
            onClick={onPrevClick}>
            <IconArrow className="rotate90" />
          </button>
          <button
            disabled={!manyLength}
            className="carousel-desk__controls__next"
            onClick={onNextClick}>
            <IconArrow className="rotate270" />
          </button>
        </div>
      </div>
    </div>
  );
}
