import React, { useMemo } from "react";
import ShowContainer from "../ShowContainer";
import IconFavorite from "../JSXIcons/IconFavorite";
import { useTranslation } from "react-i18next";
import "./game-item.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSaveSiteBeforeGame } from "../../../store/commonSlice";
import useNavigateWithSearch from "../../../hooks/useNavigateWitchSearch";
import useChooseMode from "../../../hooks/useChooseMode";
import { API_USER } from "../../../api/user";
import { DEBUG, TEST_MODE } from "../../../store/enums";
import { addFavoriteGames, removeFavoriteGames } from "../../../store/gamesSlice";
import IconProviders from "../JSXIcons/IconProviders";
import IconPeople2 from "../JSXIcons/IconPeople2";

export default function GameItem({
  className = "game",
  url = "/game/",
  image,
  hideFavorites,
  name,
  author,
  urlParam,
  isChoose = true,
  currentGame
}) {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();
  const { onChoseMode } = useChooseMode();
  const favoriteGamesId = useSelector((state) => state.games.favoriteGamesId);
  const isAuth = useSelector((state) => state.user.isAuth);

  const id = useMemo(() => currentGame.game_id || currentGame.id, [currentGame]);
  const isFavorite = useMemo(() => favoriteGamesId.includes(id), [favoriteGamesId, id]);
  const isFavoriteClass = useMemo(() => (isFavorite ? "favorite" : ""), [isFavorite]);

  const countOnline = 20;

  const onGameClick = () => {
    const pathName = window.location.pathname;

    const cbRunGame = () => {
      dispatch(setSaveSiteBeforeGame({ url: pathName, topScroll: window.scrollY }));
      navigate(url + id, { state: urlParam });
    };

    if (isChoose) {
      onChoseMode({ cbReal: cbRunGame, cbDemo: cbRunGame, game: currentGame });
    } else {
      cbRunGame();
    }
  };

  const onFavorites = (e) => {
    console.log("favoriteGamesId", favoriteGamesId);
    e.preventDefault();
    e.stopPropagation();

    if (TEST_MODE) {
      !isFavorite
        ? dispatch(addFavoriteGames(currentGame))
        : dispatch(removeFavoriteGames(currentGame));
      return;
    }

    API_USER.toggleFavorites(id)
      .then((e) => {
        if (DEBUG) console.log("favorites res", e);
        const isFavorites = e.data.favorites;
        isFavorites
          ? dispatch(addFavoriteGames(currentGame))
          : dispatch(removeFavoriteGames(currentGame));
      })
      .catch((e) => {
        if (DEBUG) console.log("catch", e);
      });
  };

  const provider = currentGame.provider;

  return (
    <div className={`${className}__item`} onClick={onGameClick}>
      <div className={`${className}__item__photo`}>
        <img src={image} />
        <ShowContainer condition={!!countOnline}>
          <span className={`${className}__item__online`}>
            <IconPeople2 />
            <span>{countOnline}</span>
          </span>
        </ShowContainer>
        <ShowContainer condition={!hideFavorites && isAuth}>
          <button
            id={id}
            data-clickid="favorites-click"
            className={`${className}__item__favorite ${isFavoriteClass}`}
            onClick={onFavorites}>
            <IconFavorite />
          </button>
        </ShowContainer>
      </div>
      <ShowContainer condition={provider}>
        <p className={`${className}__item__provider`}>
          <IconProviders />
          <span>{provider}</span>
        </p>
      </ShowContainer>
      <ShowContainer condition={name}>
        <p className={`${className}__item__name`}>{t(name)}</p>
      </ShowContainer>
      <ShowContainer condition={author}>
        <span className={`${className}__item__author`}>{t(author)}</span>
      </ShowContainer>
    </div>
  );
}
