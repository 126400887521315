import React, { useEffect, useMemo, useState } from "react";
import "./top-up-balance.scss";
import DefaultModal from "../DefaultModal";
import { useTranslation } from "react-i18next";
import CusForm from "../../mui/CusForm";
import CusInput from "../../mui/custom_input/CusInput";
import CusButton from "../../mui/custom_button/CusButton";
import { API_USER } from "../../../api/user";
import ShowContainer from "../../mui/ShowContainer";
import { DEBUG } from "../../../store/enums";
import { errorToast } from "../../mui/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency } from "../../../store/userSlice";
import { messageByCode } from "../../../utils/utils";

export default function TopUpBalance({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currency = useSelector((state) => state.user.currency);
  const currencyInfo = useSelector((state) => state.user.currencyInfo);
  const lang = useSelector((state) => state.common.lang);

  const [min, setMin] = useState(undefined);
  const [max, setMax] = useState(undefined);
  const [cryptoAddress, setCryptoAddress] = useState("");
  const [network, setNetwork] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const isCrypto = useMemo(() => currencyInfo?.type === "crypto", [currencyInfo]);

  const title = useMemo(
    () =>
      currency ? t("Top up your balance in", { currency: currency }) : t("Top up your balance"),
    [currency, t]
  );

  const checkOpen = (data) => {
    if (isOpen) {
      if (data.errorCode === 1) window.dispatchEvent(new CustomEvent("session-error"));
    }
  };

  useEffect(() => {
    if (!isCrypto || !currency) return;

    const data = { currency, lang };
    API_USER.balance.getCryptoAddress(data).then((e) => {
      if (DEBUG) console.log("get address res", e);
      const { address, network } = e.data;

      if (address || network) {
        setCryptoAddress(address);
        setNetwork(network);
        return;
      }

      errorToast(t(messageByCode(data.dataError)));
    });
  }, [isCrypto, currency, lang]);

  useEffect(() => {
    if (isCrypto) return;
    if (!currency) return;

    API_USER.balance.limit().then((e) => {
      if (DEBUG) console.log("limit deposit res", e);
      const data = e.data;
      checkOpen(e.data);
      dispatch(setCurrency(data.currency));
      setMin(data.minAmount);
      setMax(data.maxAmount);
    });
  }, [isCrypto, currency]);

  const onSubmit = async (e, field) => {
    e.preventDefault();
    setBtnLoading(true);
    console.log("field", field);

    const data = { amount: +field.amount, currency, lang };
    await API_USER.balance
      .replenish(data)
      .then((e) => {
        if (DEBUG) console.log("make deposit res", e);
        if (DEBUG && e.data.error) console.error("make deposit error", e.data.error);
        const data = e.data;

        if (data?.paymentUrl) window.open(data.paymentUrl, "_blank");
        if (data?.dataError || data?.error) return errorToast(messageByCode(data.dataError));

        closeModal();
      })
      .catch((e) => {
        if (DEBUG) console.log("make deposit cath", e);
      });

    setBtnLoading(false);
  };

  return (
    <DefaultModal isOpen={isOpen} closeModal={closeModal} classModal="top-up-balance">
      <h2>{title}</h2>

      <div className="balance__content">
        {isCrypto ? (
          <>
            <p className="balance__text">
              {t(
                "Send or more to the address below and the amount will be credited to your balance minus the network commission (approximately $1 and 1% of the replenishment amount).",
                { val: "2.00 USDT", network: network }
              )}
            </p>
            <p className="balance__text">
              {t(
                "Enrollment will take place automatically after confirmation by the crypto network."
              )}
            </p>

            <ShowContainer condition={network}>
              <p className="balance__text warn">
                {t("Send only on the _ network!!!", { val: currency + " " + network })}
              </p>
            </ShowContainer>

            <CusInput isCopy={true} defaultValue={cryptoAddress} readOnly />
          </>
        ) : (
          <>
            <p className="balance__text">
              {t("We don't require you to enter your card number! Payment is absolutely safe!")}
            </p>
            <ShowContainer condition={min || +min === 0}>
              <p className="balance__text">
                {t("Minimum deposit")}: <span className="balance__text__sum">{min}</span> {currency}
              </p>
            </ShowContainer>
            <CusForm onSubmit={onSubmit}>
              <CusInput
                label={"Replenishment amount: "}
                required
                type={"number"}
                dataType={"number"}
                name={"amount"}
                min={min}
                max={max}
              />
              <CusButton text={"Replenish"} btnLoading={btnLoading} setBtnLoading={setBtnLoading} />
            </CusForm>
          </>
        )}
      </div>
    </DefaultModal>
  );
}
