import React from "react";
import "./menu-providers.scss";
import { useTranslation } from "react-i18next";
import IconPik from "../../../mui/JSXIcons/IconPik";
import { useSelector } from "react-redux";
import useNavigateWithSearch from "../../../../hooks/useNavigateWitchSearch";
import ShowContainer from "../../../mui/ShowContainer";

export default function MenuProviders() {
  const { t } = useTranslation();
  const providers = useSelector((state) => state.games.providers);
  const navigate = useNavigateWithSearch();

  const onItemClick = (provider) => {
    navigate("/games/provider/" + provider.name);
  };

  return (
    <ShowContainer condition={providers?.length}>
      <div className="menu__providers">
        <h2>
          <IconPik />
          {t("Casino games")}
        </h2>
        <div className="menu__providers__list">
          {providers.map((provider, indx) => (
            <button
              onClick={() => onItemClick(provider)}
              className="menu__providers__list__item"
              key={indx}>
              <img className="menu__providers__list__item__img" src={provider.logo} />
              <span className="menu__providers__list__item__title">{provider.name}</span>
              <div className="menu__providers__list__item__count">
                <span>{provider.count}</span>
              </div>
            </button>
          ))}
        </div>
      </div>
    </ShowContainer>
  );
}
