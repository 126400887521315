import React from "react";

export default function IconInstagram() {
  return (
    <svg
      width="15.000000"
      height="15.000000"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M7.5 0C9.53 0 9.79 0 10.59 0.04C11.39 0.08 11.93 0.2 12.41 0.39C12.9 0.58 13.32 0.84 13.74 1.25C14.12 1.63 14.41 2.08 14.6 2.58C14.79 3.06 14.91 3.6 14.95 4.4C14.99 5.2 15 5.46 15 7.5C15 9.53 14.99 9.79 14.95 10.59C14.91 11.39 14.79 11.93 14.6 12.41C14.41 12.91 14.12 13.36 13.74 13.74C13.36 14.12 12.91 14.41 12.41 14.6C11.93 14.79 11.39 14.91 10.59 14.95C9.79 14.99 9.53 15 7.5 15C5.46 15 5.2 14.99 4.4 14.95C3.6 14.91 3.06 14.79 2.58 14.6C2.08 14.41 1.63 14.12 1.25 13.74C0.87 13.36 0.58 12.91 0.39 12.41C0.2 11.93 0.08 11.39 0.04 10.59C0 9.79 0 9.53 0 7.5C0 5.46 0 5.2 0.04 4.4C0.08 3.6 0.2 3.06 0.39 2.58C0.58 2.08 0.87 1.63 1.25 1.25C1.63 0.87 2.08 0.58 2.58 0.39C3.06 0.2 3.6 0.08 4.4 0.04C5.2 0 5.46 0 7.5 0ZM7.5 3.75C6.5 3.75 5.55 4.14 4.84 4.84C4.14 5.55 3.75 6.5 3.75 7.5C3.75 8.49 4.14 9.44 4.84 10.15C5.55 10.85 6.5 11.25 7.5 11.25C8.49 11.25 9.44 10.85 10.15 10.15C10.85 9.44 11.25 8.49 11.25 7.5C11.25 6.5 10.85 5.55 10.15 4.84C9.44 4.14 8.49 3.75 7.5 3.75ZM12.37 3.56C12.37 3.31 12.27 3.07 12.09 2.89C11.92 2.72 11.68 2.62 11.43 2.62C11.18 2.62 10.95 2.72 10.77 2.89C10.59 3.07 10.5 3.31 10.5 3.56C10.5 3.81 10.59 4.04 10.77 4.22C10.95 4.4 11.18 4.5 11.43 4.5C11.68 4.5 11.92 4.4 12.09 4.22C12.27 4.04 12.37 3.81 12.37 3.56ZM7.5 5.25C8.09 5.25 8.66 5.48 9.09 5.9C9.51 6.33 9.75 6.9 9.75 7.5C9.75 8.09 9.51 8.66 9.09 9.09C8.66 9.51 8.09 9.75 7.5 9.75C6.9 9.75 6.33 9.51 5.9 9.09C5.48 8.66 5.25 8.09 5.25 7.5C5.25 6.9 5.48 6.33 5.9 5.9C6.33 5.48 6.9 5.25 7.5 5.25Z"
        fill="#6E93A3"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}
