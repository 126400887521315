import React from "react";
import IconCircleInfo from "../JSXIcons/IconCircleInfo";
import "./circle-info.scss";

export default function CircleInfo({ info }) {
  return (
    <div className="cicle-info">
      <div className="cicle-info__popup">
        {info}
        <div className="cicle-info__popup__rect" />
        <div className="cicle-info__popup__plug" />
      </div>
      <IconCircleInfo />
    </div>
  );
}
