import React from "react";
import IconWhatsapp from "../../../mui/JSXIcons/IconWhatsapp";
import IconTg from "../../../mui/JSXIcons/IconTg";
import IconFacebook from "../../../mui/JSXIcons/IconFacebook";
import IconX from "../../../mui/JSXIcons/IconX";
import IconInstagram from "../../../mui/JSXIcons/IconInstagram";
import "./social-icons.scss";

export default function SocialIcons() {
  const social = [
    { name: "facebook", icon: <IconFacebook />, link: "" },
    { name: "telegram", icon: <IconTg />, link: "" },
    { name: "instagram", icon: <IconInstagram />, link: "" },
    { name: "x", icon: <IconX />, link: "" },
    { name: "whatsapp", icon: <IconWhatsapp />, link: "" }
  ];

  return (
    <div className="social-icons">
      {social.map((el, indx) => (
        <a
          className={`social-icons__icon ${el.name}`}
          key={indx}
          href={el.link}
          target="_blank"
          rel="noreferrer">
          {el.icon}
        </a>
      ))}
    </div>
  );
}
