import React from "react";
import "./last-champions.scss";
import IconChampionsCup from "../../mui/JSXIcons/IconChampionsCup";
import { useTranslation } from "react-i18next";
import ReactOwlCarousel from "react-owl-carousel";
import IconDolarCircle from "../../mui/JSXIcons/IconDolarCircle";
import Online from "../header/online/Online";
import IconPeople from "../../mui/JSXIcons/IconPeople";

export default function LastChampions() {
  const { t } = useTranslation();

  const champions = [
    {
      name: "*NSYNC Pop",
      image: "https://r.gamesstatic.com/games/42ea161e623b51185127b832d6fd596beeb4cce4.png",
      balanse: "2 783.79"
    },
    {
      name: "1 2 3 BOOM!",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/RelaxGaming/e0cfc16ad6584f8c847301f03bf52cd6.png",
      type: "slots",
      balanse: "2 783.79"
    },
    {
      name: "10 Burning Heart",
      image: "https://r.gamesstatic.com/games/c171398de47a084ac7a84615d5f6280b388189aa.jpg",
      balanse: "2 783.79"
    },
    {
      name: "100 Burning Hot",
      image: "https://r.gamesstatic.com/games/6456aeb10010daba0f5fc19230427654006dbe73.jpg",
      balanse: "2 783.79"
    },
    {
      name: "100 Dice",
      image: "https://r.gamesstatic.com/games/8c2ff5c68566bc1b4a9f6a6289fe9f7977afa3cb.png",
      balanse: "2 783.79"
    },
    {
      name: "100 Super Dice",
      image: "https://r.gamesstatic.com/games/f57e0be51fbdd89680a1cf69a9138e67aec5c9ad.png",
      balanse: "2 783.79"
    }
  ];

  return (
    <div className="last-champion">
      <div className="last-champion__title">
        <IconChampionsCup />
        {t("Last Champions")}
        <span className="last-champion__title__online">
          <IconPeople />
          <Online />
        </span>
      </div>
      <div className="last-champion__carusel__container">
        <ReactOwlCarousel
          autoplaySpeed={500}
          className="last-champion__carusel"
          autoplay
          loop
          margin={8}
          dots={false}
          nav={false}
          draggable={true}
          items={6}>
          {champions.map((el, indx) => {
            const balanceMain = el.balanse.split(".")?.[0];
            const balanceOther = el.balanse.split(".")?.[1];

            return (
              <div key={indx} className="champion__item">
                <img className="champion__item__img" src={el.image} />
                <div className="champion__item__info">
                  <span className="champion__item__info__title">{el.name}</span>
                  <span className="champion__item__info__balance">
                    <IconDolarCircle />
                    <span className="champion__item__info__balance__count">
                      {balanceMain}
                      <span className="opacity">.{balanceOther}</span>
                    </span>
                  </span>
                </div>
              </div>
            );
          })}
        </ReactOwlCarousel>
      </div>
    </div>
  );
}
