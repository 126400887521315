import React from "react";
import "./header-auth.scss";
import Online from "../online/Online";
import CusButton from "../../../mui/custom_button/CusButton";
import { useDispatch, useSelector } from "react-redux";
import { setModalLogin, setTypeLogin } from "../../../../store/siteSlice";
import { defaultUser, ENUM_LOGIN } from "../../../../store/enums";
import { API_USER } from "../../../../api/user";
import { setInfo, setUserId } from "../../../../store/userSlice";

export default function HeaderAuth() {
  const isAuth = useSelector((state) => state.user.isAuth);
  const dispatch = useDispatch();

  const onLoginClick = () => {
    dispatch(setTypeLogin(ENUM_LOGIN.login));
    dispatch(setModalLogin(true));
  };
  const onSignUpClick = () => {
    dispatch(setTypeLogin(ENUM_LOGIN.register));
    dispatch(setModalLogin(true));
  };

  const onExit = async () => {
    console.log("exit");
    await API_USER.logout();
    dispatch(setUserId(0));
    dispatch(setInfo(defaultUser));
  };

  return (
    <div className="header__auth">
      <Online />
      {isAuth ? (
        <CusButton text={"Exit"} onClick={onExit} />
      ) : (
        <>
          <CusButton text={"Login"} onClick={onLoginClick} />
          <CusButton text={"Sign up"} color="two" onClick={onSignUpClick} />
        </>
      )}
    </div>
  );
}
