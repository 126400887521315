import React from "react";
import "./page-witch-menu.scss";
import AsideMenu from "../../landing/menu/AsideMenu";

export default function PageWitchMenu({ className, children }) {
  return (
    <main className={`page-witch-menu ${className}`}>
      <AsideMenu />
      <div>{children}</div>
    </main>
  );
}
