import React from "react";
import { Link } from "react-router-dom";
import ShowContainer from "../../../mui/ShowContainer";
import "./logo.scss";
import { useSelector } from "react-redux";
import logoUrl from "./logo2x.png";

export default function Logo({ isFull = true }) {
  // const logoUrl = useSelector((state) => state.common.logoUrl);
  const logoText = useSelector((state) => state.common.casinoName);
  // const logoColor = useSelector((state) => state.common.logoColor);

  // TODO - узнать
  const logoColor = "#fff";

  const style = logoColor
    ? {
        background: `linear-gradient(180deg, ${logoColor}, #fff) text`,
        WebkitTextFillColor: "transparent"
      }
    : undefined;

  return (
    <Link to={"/"} className="logo">
      <img width={100} src={logoUrl} className="logo__img" loading="eager" />
      <ShowContainer condition={isFull && logoText}>
        <span style={style} className="logo__text">
          {logoText}
        </span>
      </ShowContainer>
    </Link>
  );
}
