import React from "react";

export default function IconWhatsapp() {
  return (
    <svg
      width="16.125000"
      height="16.125000"
      viewBox="0 0 16.125 16.125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M8.06 0C3.6 0 0 3.6 0 8.06C0 9.17 0.22 10.23 0.63 11.19C0.73 11.45 0.81 11.62 0.85 11.75C0.9 11.88 0.91 11.93 0.91 11.94C0.91 12.01 0.9 12.11 0.78 12.56L0.01 15.41C-0.04 15.61 0.02 15.81 0.16 15.96C0.3 16.1 0.51 16.15 0.7 16.1L3.55 15.34C4.01 15.22 4.1 15.2 4.17 15.2C4.18 15.2 4.23 15.21 4.37 15.26C4.5 15.31 4.67 15.38 4.92 15.49C5.89 15.9 6.95 16.12 8.06 16.12C12.51 16.12 16.12 12.51 16.12 8.06C16.12 3.6 12.51 0 8.06 0ZM5.79 3.75L5.72 3.74C5.36 3.74 5.03 3.74 4.7 3.89C4.46 4 4.26 4.19 4.11 4.37C3.97 4.55 3.83 4.79 3.78 5.05C3.71 5.4 3.77 5.66 3.83 5.95L3.84 5.99C4.16 7.43 4.93 8.84 6.1 10.02C7.27 11.19 8.69 11.95 10.13 12.28L10.17 12.28C10.45 12.35 10.72 12.41 11.07 12.34C11.33 12.28 11.56 12.15 11.75 12C11.93 11.85 12.11 11.65 12.22 11.42C12.37 11.09 12.37 10.76 12.37 10.4L12.37 10.33C12.37 10.16 12.36 9.88 12.26 9.63C12.13 9.32 11.87 9.07 11.45 9L11.45 9C10.93 8.92 10.55 8.86 10.27 8.82C10.13 8.8 10.02 8.78 9.93 8.77C9.85 8.76 9.75 8.75 9.67 8.76C9.3 8.78 9.01 8.93 8.78 9.08C8.64 9.18 8.48 9.32 8.36 9.42C8.31 9.46 8.27 9.5 8.23 9.53L8.17 9.57C7.95 9.75 7.84 9.84 7.71 9.84C7.57 9.83 7.47 9.75 7.27 9.57C7.14 9.46 7.02 9.34 6.9 9.22C6.77 9.1 6.66 8.97 6.54 8.85C6.37 8.65 6.28 8.55 6.28 8.41C6.28 8.27 6.36 8.16 6.54 7.94L6.59 7.88C6.62 7.85 6.66 7.8 6.7 7.76C6.8 7.63 6.93 7.48 7.03 7.33C7.18 7.11 7.33 6.81 7.35 6.45C7.36 6.36 7.35 6.26 7.34 6.19C7.33 6.1 7.32 5.98 7.3 5.84C7.25 5.57 7.19 5.18 7.11 4.67L7.11 4.66C7.05 4.25 6.8 3.99 6.49 3.86C6.23 3.75 5.95 3.75 5.79 3.75Z"
        fill="#94A4B0"
        fillOpacity="1.000000"
        fillRule="evenodd"
      />
    </svg>
  );
}
