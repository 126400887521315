import React from "react";
import "./jackpot.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IconStarSimpleThinsmooth from "../../mui/JSXIcons/IconStarSimpleThinsmooth";
import IconStarFilledThin from "../../mui/JSXIcons/IconStarFilledThin";
import CircleInfo from "../../mui/circle-info/CircleInfo";

export default function Jackpot() {
  const { t } = useTranslation();
  const jackpotBalance = "345 890 003";
  const currency = useSelector((state) => state.user.currency);

  //NEED добавить инфу в dop
  const cards = [
    {
      title: "Grande Jackpot",
      dop: "Play the bonus games for your chance to win the jackpot. The minimum total bet in the game must be at least 100 RUB. on spin",
      balance: jackpotBalance
    },
    {
      title: "Grande Jackpot",
      dop: "Play the bonus games for your chance to win the jackpot. The minimum total bet in the game must be at least 100 RUB. on spin",
      balance: jackpotBalance
    },
    {
      title: "Grande Jackpot",
      dop: "Play the bonus games for your chance to win the jackpot. The minimum total bet in the game must be at least 100 RUB. on spin",
      balance: jackpotBalance
    }
  ];

  return (
    <div className="jackpot">
      <div className="jackpot__main">
        <span className="jackpot__main__title">
          {t("Total jackpot")} <IconStarFilledThin />
        </span>
        <span className="jackpot__main__balance">
          <span className="jackpot__main__balance__value">{jackpotBalance}</span>
          <span className="jackpot__main__balance__currency">{currency}</span>
          <IconStarSimpleThinsmooth />
        </span>
      </div>
      <div className="jackpot__cards">
        {cards.map((card, indx) => (
          <div key={indx} className="jackpot__card">
            <span className="jackpot__card__title">
              {card.title}
              <CircleInfo info={card.dop} />
            </span>
            <span className="jackpot__card__balance">
              <span className="jackpot__card__balance__value">{card.balance}</span>
              <span className="jackpot__card__balance__current">{currency}</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
