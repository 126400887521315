import React from "react";

export default function IconFacebook() {
  return (
    <svg
      width="15.000000"
      height="14.962524"
      viewBox="0 0 15 14.9625"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M15 7.5C15 3.35 11.64 0 7.5 0C3.35 0 0 3.35 0 7.5C0 11.13 2.58 14.15 6 14.85L6 9.75L4.5 9.75L4.5 7.5L6 7.5L6 5.62C6 4.17 7.17 3 8.62 3L10.5 3L10.5 5.25L9 5.25C8.58 5.25 8.25 5.58 8.25 6L8.25 7.5L10.5 7.5L10.5 9.75L8.25 9.75L8.25 14.96C12.03 14.58 15 11.39 15 7.5Z"
        fill="#6E93A3"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}
