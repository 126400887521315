import React from "react";
import "./main.scss";
import Banners from "../components/landing/banners/Banners";
import CatalogGame from "../components/landing/catalog_game/CatalogGame";
import Header from "../components/landing/header/Header";
import Footer from "../components/landing/footer/Footer";
import useBalanceUpdate from "../hooks/useBalanceUpdate";
import LastChampions from "../components/landing/last_champions/LastChampions";
import Jackpot from "../components/landing/jackpot/Jackpot";
import TypeGamesMenu from "../components/landing/type_games_menu/TypeGamesMenu";
import { ENUM_GAME_TYPE } from "../store/enums";
import FindGameLine from "../components/landing/find_game_line/FindGameLine";
import LatestRates from "../components/landing/latest_rates/LatestRates";
import PageWitchMenu from "../components/mui/page_witch_menu/PageWitchMenu";

export default function Main() {
  useBalanceUpdate(true);

  return (
    <PageWitchMenu className={"landing"}>
      <Header />
      <LastChampions />
      <Banners />
      <Jackpot />
      <TypeGamesMenu activeTab={ENUM_GAME_TYPE.lobby} />
      <FindGameLine />
      <CatalogGame />
      {/* <Favorites /> */}
      <LatestRates />
      <Footer />
    </PageWitchMenu>
  );
}
