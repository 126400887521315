import React from "react";

export default function IconMedal() {
  return (
    <svg
      width="24.000000"
      height="24.000000"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip4156_6340">
          <rect
            id="Bold / Like / Medal Ribbon Star"
            rx="4.500000"
            width="23.000000"
            height="23.000000"
            transform="translate(0.500000 0.500000)"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#clip4156_6340)">
        <path
          id="Vector"
          d="M12 16C15.86 16 19 12.86 19 9C19 5.13 15.86 2 12 2C8.13 2 5 5.13 5 9C5 12.86 8.13 16 12 16ZM12 6C11.71 6 11.52 6.34 11.14 7.02L11.04 7.19C10.93 7.39 10.88 7.48 10.8 7.55C10.71 7.61 10.61 7.64 10.4 7.68L10.21 7.73C9.47 7.89 9.1 7.98 9.01 8.26C8.92 8.54 9.18 8.84 9.68 9.42L9.81 9.58C9.95 9.74 10.02 9.83 10.06 9.93C10.09 10.03 10.08 10.15 10.06 10.37L10.04 10.57C9.96 11.36 9.92 11.75 10.15 11.92C10.38 12.1 10.73 11.94 11.42 11.62L11.6 11.54C11.79 11.45 11.89 11.4 12 11.4C12.1 11.4 12.2 11.45 12.39 11.54L12.57 11.62C13.26 11.94 13.61 12.1 13.84 11.92C14.07 11.75 14.03 11.36 13.95 10.57L13.93 10.37C13.91 10.15 13.9 10.03 13.93 9.93C13.97 9.83 14.04 9.74 14.18 9.58L14.31 9.42C14.81 8.84 15.07 8.54 14.98 8.26C14.89 7.98 14.52 7.89 13.78 7.73L13.59 7.68C13.38 7.64 13.28 7.61 13.19 7.55C13.11 7.48 13.06 7.39 12.95 7.19L12.85 7.02C12.47 6.34 12.28 6 12 6Z"
          fill="#20A1FF"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
        <path
          id="Vector"
          d="M7.09 15.94L6.71 17.32C6.08 19.61 5.77 20.76 6.19 21.38C6.33 21.6 6.53 21.78 6.76 21.9C7.41 22.23 8.42 21.7 10.43 20.65C11.1 20.3 11.44 20.13 11.8 20.09C11.93 20.08 12.06 20.08 12.19 20.09C12.55 20.13 12.89 20.3 13.56 20.65C15.57 21.7 16.58 22.23 17.23 21.9C17.46 21.78 17.66 21.6 17.8 21.38C18.22 20.76 17.91 19.61 17.28 17.32L16.9 15.94C15.52 16.92 13.82 17.5 12 17.5C10.17 17.5 8.47 16.92 7.09 15.94Z"
          fill="#20A1FF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
