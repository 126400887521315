import React, { useMemo } from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ShowContainer from "../../mui/ShowContainer";
import Logo from "../header/logo/Logo";
import SocialIcons from "../menu/social_icons/SocialIcons";
import ChoseLang from "../../mui/chose_lang/ChoseLang";
import ButtonUp from "../../mui/button_up/ButtonUp";
import imgGooglePlay from "./googleplay.svg";
import imgAppstore from "./appstore.svg";
import imgApleLogo from "./apple.svg";
import imgGpLogo from "./gp.svg";
import IconTg from "../../mui/JSXIcons/IconTg";
import { setPromoCode } from "../../../store/siteSlice";

export default function Footer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const botName = useSelector((state) => state.common.botName);
  const supportChat = useSelector((state) => state.common.supportChat);
  const isTgBot = useSelector((state) => state.common.isTgBot);

  const year = useMemo(() => new Date().getFullYear(), []);
  const logoText = useSelector((state) => state.common.casinoName);

  // TODO
  const casinoGameLink = "";

  const onPromoClick = () => dispatch(setPromoCode(true));

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__content__one">
          <Logo />
          <div className="footer__content__contents">
            <span className="footer__content__contents__title">{t("Contents")}</span>
            <div className="footer__content__contents__items">
              <Link>{t("Providers")}</Link>
              <button onClick={onPromoClick}>{t("Promo")}</button>
              <Link to={"/games/bonus"}>{t("Bonus Game")}</Link>
              <Link to={"/games/hit"}>{t("Hit")}</Link>
              <a href={casinoGameLink}>{t("Casino games")}</a>
              <Link to={"/terms"}>{t("Usage agreement")}</Link>
            </div>
          </div>
          <div className="footer__content__controls">
            <ChoseLang />
            <ButtonUp />
          </div>
        </div>

        <div className="footer__content__two">
          <div className="footer__content__two__info">
            <span className="footer__content__two__info__title">{t("Download our app")}</span>
            <span className="footer__content__two__info__desk">
              {t("Find our slots on Google or Apple Play")}
            </span>
          </div>
          <div className="footer__content__two__links">
            <div className="footer__content__two__links__googleplay">
              <img src={imgGooglePlay} />
            </div>
            <div className="footer__content__two__links__appstore">
              <img src={imgAppstore} />
            </div>
            <div className="footer__content__two__links__gp">
              <img src={imgGpLogo} />
            </div>
            <div className="footer__content__two__links__apple">
              <img src={imgApleLogo} />
            </div>
          </div>
        </div>

        <div className="footer__content__three">
          <SocialIcons />
          <div className="footer__content__three__tg">
            <ShowContainer condition={!isTgBot}>
              <ShowContainer condition={botName}>
                <a
                  className="footer__play-tg"
                  target="_blank"
                  href={`https://t.me/${botName}`}
                  rel="noreferrer">
                  <span className="footer__play-tg__title">{t("Play as a bot:")}</span>
                  <span className="footer__play-tg__name">
                    <IconTg />@{botName}
                  </span>
                </a>
              </ShowContainer>

              <ShowContainer condition={supportChat}>
                <a
                  className="footer__support-tg"
                  target="_blank"
                  href={`https://t.me/${supportChat}`}
                  rel="noreferrer">
                  <span className="footer__support-tg__title">{t("Support: ")}</span>
                  <span className="footer__support-tg__name">
                    <IconTg />@{supportChat}
                  </span>
                </a>
              </ShowContainer>
            </ShowContainer>
          </div>
          <span className="footer__copyright">
            © {year} {logoText}
          </span>
        </div>
      </div>
    </footer>
  );
}
