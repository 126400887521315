import React from "react";
import "./login-modal.scss";
import DefaultModal from "../DefaultModal";
import LoginImg from "./LoginImg";
import LoginFields from "./LoginFields";

export default function LoginModal({ isOpen, closeModal, data: dataLogin, hideCross }) {
  return (
    <DefaultModal
      classModal="login-modal"
      isOpen={isOpen}
      closeModal={closeModal}
      hideClose={hideCross}>
      <LoginImg />
      <LoginFields dataLogin={dataLogin} classModal={closeModal} />
    </DefaultModal>
  );
}
