import React from "react";

export default function IconX() {
  return (
    <svg
      width="14.625000"
      height="14.625000"
      viewBox="0 0 14.625 14.625"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs />
      <path
        id="Vector"
        d="M0.08 0.39C0.2 0.15 0.45 0 0.73 0L4.38 0C4.62 0 4.84 0.11 4.98 0.3L8.46 5.12L13.37 0.21C13.66 -0.08 14.12 -0.08 14.41 0.21C14.69 0.49 14.69 0.96 14.41 1.24L9.33 6.32L14.48 13.46C14.64 13.68 14.66 13.98 14.54 14.22C14.41 14.47 14.16 14.62 13.89 14.62L10.23 14.62C10 14.62 9.78 14.51 9.64 14.32L6.16 9.49L1.24 14.41C0.96 14.69 0.5 14.69 0.21 14.41C-0.08 14.12 -0.08 13.66 0.21 13.37L5.29 8.29L0.13 1.15C-0.03 0.93 -0.05 0.64 0.08 0.39Z"
        fill="#94A4B0"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
}
