import React from "react";
import { useSelector } from "react-redux";

import "./header.scss";
import HeaderNav from "./nav/HeaderNav";
import HeaderAuth from "./auth/HeaderAuth";

export default function Header() {
  const isAuth = useSelector((state) => state.user.isAuth);
  const headerClass = isAuth ? "auth" : "no-auth";

  return (
    <header className={`header ${headerClass}`}>
      <HeaderNav />
      <HeaderAuth />
    </header>
  );
}
