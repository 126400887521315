import React from "react";
import "./header-nav.scss";

import { setPromoCode } from "../../../../store/siteSlice";
import { useDispatch } from "react-redux";
import IconProviders from "../../../mui/JSXIcons/IconProviders";
import IconHit from "../../../mui/JSXIcons/IconHit";
import IconBonus from "../../../mui/JSXIcons/IconBonus";
import IconPromo from "../../../mui/JSXIcons/IconPromo";
import { useTranslation } from "react-i18next";
import useNavigateWithSearch from "../../../../hooks/useNavigateWitchSearch";

export default function HeaderNav() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch();

  const navs = [
    { title: "Providers", img: <IconProviders />, link: "/games/providers" },
    { title: "Hit", img: <IconHit />, link: "/games/hit" },
    { title: "Bonus games", img: <IconBonus />, link: "/games/bonus" },
    { title: "Promo", img: <IconPromo />, onClick: () => dispatch(setPromoCode(true)) }
  ];

  const onBtnClick = (item) => {
    if (item.link) navigate(item.link);
    item?.onClick?.();
  };

  return (
    <nav className="header__nav">
      {navs.map((item, indx) => (
        <button key={indx} onClick={() => onBtnClick(item)}>
          {item.img} <span>{t(item.title)}</span>
        </button>
      ))}
    </nav>
  );
}
