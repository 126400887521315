import React from "react";

export default function IconLook() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37533 8.37884V6.66666C4.37533 3.56006 6.89372 1.04166 10.0003 1.04166C13.1069 1.04166 15.6253 3.56006 15.6253 6.66666V8.37884C16.5543 8.44822 17.1592 8.62337 17.6014 9.06556C18.3337 9.7978 18.3337 10.9763 18.3337 13.3333C18.3337 15.6904 18.3337 16.8689 17.6014 17.6011C16.8692 18.3333 15.6907 18.3333 13.3337 18.3333H6.66699C4.30997 18.3333 3.13146 18.3333 2.39923 17.6011C1.66699 16.8689 1.66699 15.6904 1.66699 13.3333C1.66699 10.9763 1.66699 9.7978 2.39923 9.06556C2.84142 8.62337 3.44636 8.44822 4.37533 8.37884ZM5.62533 6.66666C5.62533 4.25042 7.58408 2.29166 10.0003 2.29166C12.4166 2.29166 14.3753 4.25042 14.3753 6.66666V8.33631C14.0562 8.33333 13.7099 8.33333 13.3337 8.33333H6.66699C6.29073 8.33333 5.9445 8.33333 5.62533 8.33631V6.66666ZM10.0003 11.0417C10.3455 11.0417 10.6253 11.3215 10.6253 11.6667V15C10.6253 15.3452 10.3455 15.625 10.0003 15.625C9.65515 15.625 9.37533 15.3452 9.37533 15V11.6667C9.37533 11.3215 9.65515 11.0417 10.0003 11.0417Z"
        fill="#5280A3"
      />
    </svg>
  );
}
