import React from "react";
import "./banners.scss";
import { useTranslation } from "react-i18next";
import CusButton from "../../mui/custom_button/CusButton";
import imgGirlWelcom from "./welcom-girl.png";
import imgGirlBonus from "./bonus-girl.png";

export default function Banners() {
  const { t } = useTranslation();

  return (
    <article className="banners">
      <section className="banners__welcome">
        <div className="banners__welcome__info">
          <h2>{t("Welcome to the world of luck and big winnings!")}</h2>
          <p>{t("Start playing now!")}</p>
          <CusButton text={"Go"} color="blue" />
        </div>
        <img src={imgGirlWelcom} />
        <div className="bottom-blur" />
      </section>
      <section className="banners__bonus">
        <div className="banners__bonus__info">
          <h2>{t("All bonuses for you:")}</h2>
          <p>{t("All bonuses in one place: welcome, no deposit and much more")}</p>
          <CusButton text={"Go"} color="blue" />
        </div>
        <img src={imgGirlBonus} />
        <div className="bottom-blur" />
      </section>
    </article>
  );
}
