import React from "react";
import "./bonus-menu.scss";
import { useTranslation } from "react-i18next";
import CusButton from "../../../mui/custom_button/CusButton";
import imgPresent from "./present.png";

export default function BonusMenu() {
  const { t } = useTranslation();
  return (
    <div className="bonus-menu">
      <h2>{t("Играй в казино игры и получай бонусы!")}</h2>
      <CusButton color="blue" text={"Play"} />
      <img src={imgPresent} />
      <div className="bonus-menu__gradient" />
    </div>
  );
}
